<template>
  <div id="content">
    <div class="row">
      <div class="col-12"></div>
    </div>
    <section class="main__content--frames">
      <div class="row d-none d-md-flex">
        <div class="col-7">
          <router-link to="/" class="link">
            <div class="row">
              <img src="/img/logomlac.svg" class="col-4" />
            </div>
          </router-link>
        </div>
        <div class="col-5">
          <a href="#" class="right" @click="$router.go(-1)"> Volver </a>
        </div>
      </div>
      <div id="info">
        <router-link to="/group" class="left"> Administrar grupos </router-link>
        <div id="activity">
          {{ statusMessage }}
        </div>
      </div>

      <div id="filter">
        <div id="counter" class="left">
          {{ filteredUsers.length }} usuarios encontrados
        </div>
        <form id="filter-form" class="right">
          <div class="form-group left">
            <input
              class="form-control group-input"
              placeholder="Buscar usuario"
              v-model="filterName"
              @keyup="reload"
            />
          </div>
          <div class="form-group right">
            <select
              class="form-control group-input"
              v-model="selectedGroup"
              @change="reload"
            >
              <option selected value="">Todos los grupos</option>
              <option
                v-for="group in groups"
                :key="group.id"
                v-bind:value="group.name"
              >
                {{ group.name }}
              </option>
            </select>
          </div>
        </form>
      </div>
      <center v-if="loading" class="loading">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </center>
      <div v-else>
        <b-card sub-title="Información" v-if="users.length === 0">
          <b-card-text> No hay usuarios para mostrar </b-card-text>
        </b-card>
        <div class="table-wrapper-scroll-y my-custom-scrollbar" id="data-zone">
          <table class="table table-dark">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nombre</th>
                <th scope="col" v-for="group in groups" :key="group.name">
                  {{ group.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in filteredUsers" :key="user.ID">
                <th scope="row">{{ user.ID }}</th>
                <td>{{ user.display_name }}</td>
                <td v-for="group in groups" :key="group.name">
                  <input
                    v-bind:id="getCheckboxId(user.ID, group.id)"
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    v-bind:checked="findGroup(user.groups, group.name)"
                    @change="toggleChecked(user.ID, group.id)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import userApi from "../api/user";
import groupApi from "../api/groups";
export default {
  data: function () {
    return {
      loading: true,
      filterName: "",
      users: [],
      groups: [],
      selectedGroup: "",
      statusMessage: "",
      needsReload: false,
    };
  },
  created: function () {
    this.getGroups();
    this.getUsers();
  },
  computed: {
    filteredUsers() {
      let finalUsers = this.users;

      if (this.selectedGroup.length > 0) {
        finalUsers = finalUsers.filter((user) =>
          this.findGroup(user.groups, this.selectedGroup)
        );
      }

      if (this.filterName) {
        finalUsers = finalUsers.filter(
          (user) =>
            user.display_name.toLowerCase().indexOf(this.filterName) > -1
        );
      }

      return finalUsers;
    },
  },
  methods: {
    reload: function () {
      if (this.needsReload) {
        this.getUsers();
      }
    },
    getFilterId: function (group_id) {
      //Devuelve el identificador del checkbox crado mediante iteración
      return "fil-" + group_id;
    },
    getCheckboxId: function (user_id, group_id) {
      //Devuelve el identificador del checkbox crado mediante iteración
      return "chk-" + group_id + "-" + user_id;
    },
    toggleChecked: async function (user_id, group_id) {
      const checkboxId = this.getCheckboxId(user_id, group_id);
      const checkBox = document.getElementById(checkboxId);
      const payload = { user_id: user_id, group_id: group_id };
      this.statusMessage = "Guardando...";
      if (checkBox.checked === true) {
        await this.addToGroup(payload);
      } else {
        await this.removeFromGroup(payload);
      }
      console.debug("socket.emit: Group change; event update");
      this.$root.socket.emit("transmision", true);
    },
    addToGroup: function (payload) {
      return userApi
        .addToGroup(payload)
        .then(() => {
          this.statusMessage = "Cambios guardados";
          this.needsReload = true;
        })
        .catch(() => {
          this.statusMessage = "Error al guardar";
        });
    },
    removeFromGroup: function (payload) {
      return userApi
        .removeFromGroup(payload)
        .then(() => {
          this.statusMessage = "Cambios guardados";
          this.needsReload = true;
        })
        .catch(() => {
          this.statusMessage = "Error al guardar";
        });
    },
    findGroup: function (groups, search) {
      let resp = false;
      groups.forEach((element) => {
        if (element.name === search) {
          resp = true;
        }
      });
      return resp;
    },
    getUsers: function () {
      this.loading = true;
      userApi
        .get()
        .then((res) => {
          this.users = res.data;
          this.loading = false;
          this.needsReload = false;
        })
        .catch(() => {
          this.statusMessage = "Ha ocurrido un error obteniendo los datos";
        });
    },
    getGroups: function () {
      this.loading = true;
      groupApi
        .get()
        .then((res) => {
          this.groups = res.data;
        })
        .catch(() => {
          this.statusMessage = "Ha ocurrido un error obteniendo los datos";
        });
    },
  },
};
</script>
<style>
.my-custom-scrollbar {
  position: relative;
  height: 550px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
#info {
  height: 20px;
  padding-top: 5px;
}
#activity {
  color: rgb(194, 192, 192);
  font-size: 13px;
  text-align: right;
  padding-bottom: 10px;
}
#filter {
  color: white;
  font-size: 13px;
  margin-top: 10px;
  height: 85px;
}
#counter {
  margin-top: 63px;
}
#filter-form {
  height: 70px;
  color: white;
  width: 500px;
  padding: 20px;
  background: #454d55;
  border-radius: 10px;
  margin-top: 10px;
}
.left {
  float: left;
}
.right {
  float: right;
}
.group-input {
  width: 14rem !important;
}
</style>
