<template>
  <div id="video-wrapper" class="bntv__video-wrapper" :class="tvClass">
    <template v-if="shouldShowVideo">
      <mux-player
        v-if="video.type === 'mux'"
        :playback-id="video.id"
        :metadata-video-id="video.id"
        :metadata-video-title="event.title"
        stream-type="live"
        autoplay
      />
      <iframe
        v-if="video.type === 'vimeo'"
        class="embed-responsive-item"
        v-bind:src="video.source"
        allow="autoplay; fullscreen"
        allowfullscreen
        title="vimeo-player"
      ></iframe>
      <div
        v-if="video.type === 'youtube'"
        class="video-overlay"
        refs="youtube"
        @click="youtubePlayToggle"
      >
        <div class="video-controls">
          <button class="stop-button" @click="youtubePlayToggle"></button>
          <button
            :class="{ exit: isFullscreen }"
            class="fullscreen-button"
            @click.stop="toggleFullscreen"
          ></button>
        </div>
      </div>
      <youtube
        v-if="video.type === 'youtube'"
        :video-id="video.id"
        :player-vars="playerVars"
        ref="youtube"
        :class="{ fullscreen: isFullscreen }"
      ></youtube>
    </template>
    <div v-else class="video-background">
      <p>
        <button class="btn-show-video" @click="showVideo"></button>
      </p>
      <p class="d-none d-sm-block">Ver transmisión</p>
    </div>
  </div>
</template>

<script>
import environment from "../../env";
import { parseVideoURL, getSource } from "../services/video";
import { mapGetters } from "vuex";
import DeviceDetector from "device-detector-js";
import "@mux/mux-player";

export default {
  name: "EventVideo",
  data: function () {
    return {
      playerVars: {
        autoplay: 1,
        controls: 0,
        disablekb: 1,
        iv_load_policy: 3,
        modestbranding: 1,
        origin: environment.frontend_url,
        rel: 0,
        showinfo: 0,
        enablejsapi: 1,
        playsinline: 1,
      },
      device: {},
      shouldShowVideo: true,
      isFullscreen: false,
    };
  },
  created: function () {
    const deviceDetector = new DeviceDetector();
    this.device = deviceDetector.parse(navigator.userAgent);
    this.shouldShowVideo = this.device.device.type === "television";
  },
  computed: {
    ...mapGetters({
      event: "events/getActiveEvent",
    }),
    video: function () {
      let video = parseVideoURL(this.event.link);
      video.source = getSource(this.event.link);
      video.title = this.event.title;
      return video;
    },
    tvClass: function () {
      if (this.video.type === "mux" && this.shouldShowVideo) {
        return "col-md-7 col-sm-12 embed-responsive video";
      }
      if (this.event.chat) {
        return "col-md-7 col-sm-12 embed-responsive embed-responsive-16by9 video";
      }
      return "col-md-12 col-sm-12 embed-responsive embed-responsive-16by9 video";
    },
  },
  methods: {
    showVideo: function () {
      this.shouldShowVideo = true;
    },
    youtubePlayToggle: function () {
      let self = this;
      this.$refs.youtube.player.getPlayerState().then((state) => {
        // el estado 1 en el iframe de youtube corresponde al estado `playing`
        if (state !== 1) {
          self.$refs.youtube.player.playVideo();
        } else {
          self.shouldShowVideo = false;
        }
      });
    },
    toggleFullscreen: function () {
      this.isFullscreen ? this.exitFullscreen() : this.showFullscreen();
    },
    showFullscreen: function () {
      const fsWrapper = document.querySelector("#video-wrapper");
      const requestFullscreen =
        fsWrapper.requestFullscreen ||
        fsWrapper.mozRequestFullScreen ||
        fsWrapper.webkitRequestFullscreen ||
        fsWrapper.msRequestFullscreen;

      if (requestFullscreen) {
        requestFullscreen.call(fsWrapper);
      } else {
        this.applyFullscreenStyles(fsWrapper);
      }

      this.isFullscreen = true;
    },
    exitFullscreen: function () {
      const fsWrapper = document.querySelector("#video-wrapper");
      const exitFullscreen =
        document.exitFullscreen ||
        document.mozCancelFullScreen ||
        document.webkitExitFullscreen ||
        document.msExitFullscreen;

      if (exitFullscreen) {
        exitFullscreen.call(document);
      } else {
        this.resetFullscreenStyles(fsWrapper);
      }

      this.isFullscreen = false;
    },
    applyFullscreenStyles: function (element) {
      element.style.position = "fixed";
      element.style.top = "0";
      element.style.left = "0";
      element.style.width = "100vw";
      element.style.height = "100vh";
      element.style.zIndex = "9999";
      element.style.backgroundColor = "black";
    },
    resetFullscreenStyles: function (element) {
      element.style.position = "";
      element.style.top = "";
      element.style.left = "";
      element.style.width = "";
      element.style.height = "";
      element.style.zIndex = "";
      element.style.backgroundColor = "";
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");

.video {
  margin-bottom: 15px;
}

#frames .embed-responsive {
  background-image: url("../../public/img/video-background.svg");
  background-size: cover;
  background-position: 0;
}

.video-background {
  z-index: 50;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  padding-top: 20%;
}

.btn-show-video {
  width: 100px;
  height: 100px;
  border: 0;
  background-image: url("../assets/btn-play-light.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
}

.btn-show-video:hover {
  background-image: url("../assets/btn-play-dark.svg");
}

.btn-show-video:active {
  background-image: url("../assets/btn-play-light.svg");
}

.video-background p {
  font-family: "Poppins", sans-serif;
  color: white;
  text-align: center;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.video-controls {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px;
  z-index: 50;
}

.video-controls button {
  background-color: rgba(0, 0, 0, 0.3);
  background-repeat: no-repeat;
  background-size: cover;
  height: 30px;
  width: 30px;
  margin-left: 5px;
  border: none;
  border-radius: 3px;
  z-index: 50;
}

.video-controls .stop-button {
  background-image: url("../assets/btn-stop.svg");
}

.video-controls .fullscreen-button {
  background-image: url("../assets/btn-fullscreen.svg");
}

.video-controls .fullscreen-button.exit {
  background-image: url("../assets/btn-exit-fullscreen.svg") !important;
}

mux-player {
  --seek-live-button: none;
  --seek-backward-button: none;
  --seek-forward-button: none;
  --time-range: none;
}

@media (max-width: 500px) {
  .btn-show-video {
    width: 60px;
    height: 60px;
  }
}
</style>
