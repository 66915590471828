export default {
  logout: function (socket, router) {
    this.clearLocalStorage();
    if (socket) {
      socket.disconnect();
    }
    router.push("/login");
  },
  clearLocalStorage: () => {
    delete window.localStorage.token;
    delete window.localStorage.user;
    delete window.localStorage.capabilities;
    delete window.localStorage.invitado;
  },
  checkLocalStorageForAuth: () => {
    try {
      const hasUser = !!JSON.parse(window.localStorage.user);
      return hasUser;
    } catch (error) {
      return false;
    }
  },
  isStoredDataComplete: function (user) {
    if (!user) {
      return false;
    }

    const userId = user.ID || user.invitacion_id;
    if (!userId) {
      return false;
    }

    const requiredFieldsForUsers = [
      "display_name",
      "images",
      "meta",
      "user_email",
      "user_status",
    ];
    const requiredFieldsForGuests = ["display_name", "email", "can_chat"];
    let fieldsToCheck = [];

    if (user.invitacion_id) {
      fieldsToCheck = requiredFieldsForGuests;
    } else {
      fieldsToCheck = requiredFieldsForUsers;
    }

    for (const field of fieldsToCheck) {
      const hasProperty = Object.prototype.hasOwnProperty.call(user, field);
      if (!hasProperty) {
        return false;
      }
    }

    if (user.invitacion_id) {
      return true;
    }

    const levels = Object.keys(user).filter((key) =>
      key.includes("user_level")
    );
    const capabilities = Object.keys(user).filter((key) =>
      key.includes("capabilities")
    );

    if (!levels.length || !capabilities.length) {
      return false;
    }

    return true;
  },
};
