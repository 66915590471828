export default {
  getDate: function (date = "") {
    if (!date) {
      date = new Date();
      date = date.toLocaleString("es-CL", { timeZone: "America/Santiago" });
      date =
        date.substring(6, 10) +
        "-" +
        date.substring(3, 5) +
        "-" +
        date.substring(0, 2);
    } else {
      date = date.toISOString().substring(0, 10);
    }
    return date;
  },
  getExpirationDate: function (date) {
    let expiration_date = new Date(date);
    expiration_date.setDate(expiration_date.getDate() + 1);
    expiration_date = this.getDate(expiration_date);
    return expiration_date + "T18:00";
  },
};
