<template>
  <div>
    <b-modal
      ref="survey-enablement"
      id="survey-enablement"
      title="Habilitación de encuesta"
      v-model="showSurveyEnablement"
    >
      <div class="modal-body">
        <form id="surveySubmitForm" v-on:submit.prevent="saveSurvey()">
          <div class="form-group">
            <label>Tipo de encuesta</label>
            <select class="form-control" v-model="template_id" required>
              <option disabled value="">Seleccione una opción</option>
              <option
                v-for="template in templates"
                v-bind:key="template.id"
                v-bind:value="template.id"
              >
                {{ template.name }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label>Fecha Evento</label>
            <input type="date" class="form-control" v-model="date" required />
          </div>

          <div class="form-group">
            <label>Título</label>
            <input
              type="text"
              class="form-control"
              placeholder="Escriba aquí"
              v-model="title"
              required
            />
          </div>

          <div class="form-group">
            <label>Fecha de expiración</label>
            <input
              type="datetime-local"
              class="form-control"
              placeholder="Escriba aquí"
              v-model="expiration_date"
              required
            />
          </div>
        </form>
        <div v-if="error">
          Ha ocurrido un error trayendo los datos, por favor contacte al
          administrador o inténtelo más tarde. Detalle: {{ error }}
        </div>
      </div>
      <template #modal-footer="{}">
        <div>
          <b-button
            type="submit"
            size="md"
            variant="primary"
            form="surveySubmitForm"
          >
            {{ submitLabel }}</b-button
          >
        </div>
        <div>
          <b-button
            id="stopSurveyButton"
            size="md"
            variant="danger"
            @click="stopSurvey()"
            v-if="activeSurvey"
            >Detener encuesta activa</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import surveyApi from "../api/survey";
import surveyService from "../services/survey";

export default {
  data: function () {
    return {
      id: "",
      loading: false,
      submitLabel: "Iniciar Nueva Encuesta",
      title: "",
      date: "",
      template: {},
      template_id: "",
      error: "",
    };
  },
  mounted: async function () {
    try {
      await this.getTemplates();
      this.date = surveyService.getDate();
    } catch (error) {
      this.error = error;
    }
  },
  computed: {
    ...mapGetters({
      activeSurvey: "survey/getActiveSurvey",
      templates: "survey/getTemplates",
    }),
    showSurveyEnablement: {
      get: function () {
        return this.$store.state.survey.showSurveyEnablement;
      },
      set: function (val) {
        return this.$store.dispatch("survey/setShowSurveyEnablement", val);
      },
    },
    expiration_date: {
      get: function () {
        if (!this.date) {
          return "";
        }
        return surveyService.getExpirationDate(this.date);
      },
      set: function (newValue) {
        return newValue;
      },
    },
  },
  methods: {
    getTemplates: function () {
      return this.$store.dispatch("survey/getTemplates");
    },
    saveSurvey: function () {
      const payload = {
        date: this.date,
        title: this.title,
        template_id: this.template_id,
        created_by: this.$root.user.ID,
        expiration_date: this.expiration_date,
      };
      surveyApi.saveSurvey(payload).then(() => {
        console.debug("socket.emit: Enable Survey");
        this.$root.socket.emit("survey");
        this.$refs["survey-enablement"].hide();
      });
    },
    stopSurvey: function () {
      surveyApi.deleteSurvey(this.activeSurvey.id).then(() => {
        console.debug("socket.emit: Disable Survey");
        this.$root.socket.emit("survey");
        this.$refs["survey-enablement"].hide();
      });
    },
    showModal: function () {
      this.$refs["survey-enablement"].show();
    },
  },
};
</script>
