import utils from "../utils";
import userService from "../services/user";

export default {
  addNewMessage: function (
    messageList,
    user,
    event,
    message,
    imageURL = null,
    userColor = null,
    userInital = null
  ) {
    const newMessage = {
      user_id: user.ID,
      culto_id: event.id,
      signed_urls_id: user.invitacion_id,
      display_name: user.display_name,
      message,
      imageURL,
      userColor,
      userInital,
      hash: utils.createHash(),
      created_at: Math.floor(Date.now() / 1000),
    };
    this.pushMessageIntoMessageList(messageList, newMessage);

    return newMessage;
  },
  pushMessageIntoMessageList: (messageList, message) => {
    if (!messageList.some((item) => item.hash === message.hash)) {
      messageList.push(message);
    }
  },
  shouldShowDeleteButton: (chat, user) => {
    return (
      (userService.userIsAdmin(user) ||
        userService.userIsMessageAuthor(chat, user)) &&
      chat.status !== 0
    );
  },
  removeMessage: (chatList, chat) => {
    if (chatList.length > 0) {
      chatList.map((item) => {
        if (item.hash === chat.hash) {
          item.message = "(Este mensaje ha sido eliminado)";
          item.status = 0;
        }
      });
    }
  },
};
