<template>
  <div>
    <b-modal ref="transmision" id="transmision" title="Transmisión de Video">
      <div class="modal-body">
        <form v-on:submit.prevent="submitEvent()" id="eventSubmitForm">
          <div class="form-group">
            <label>Link de Youtube o Vimeo</label>
            <input
              type="text"
              class="form-control"
              placeholder="Pegue aquí la URL del video"
              v-model="id"
              required
            />
          </div>

          <div class="form-group">
            <label>Título del Evento</label>
            <input
              type="text"
              class="form-control"
              placeholder="Escriba aquí"
              v-model="title"
              required
            />
          </div>

          <div class="form-group">
            <label>Tipo de Evento</label>
            <select class="form-control" v-model="type" required>
              <option disabled value="">Seleccione</option>
              <option value="culto">Culto</option>
              <option value="doctrinal">Doctrinal</option>
              <option value="reunion">Reunión especial</option>
            </select>
          </div>
          <div class="form-group">
            <label>Restricción a un grupo</label>
            <select class="form-control" v-model="event_group_id">
              <option value="">Todos</option>
              <option
                v-for="group in groups"
                v-bind:key="group.id"
                v-bind:value="group.id"
              >
                {{ group.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <b-form-checkbox
              id="survey"
              v-model="survey_dispatch"
              name="survey"
              value="accepted"
              unchecked-value="not_accepted"
              v-if="event.id"
            >
              Iniciar encuesta al detener
            </b-form-checkbox>
          </div>
        </form>
      </div>

      <template #modal-footer="{}">
        <div>
          <b-button
            type="submit"
            size="md"
            variant="primary"
            form="eventSubmitForm"
          >
            {{ submitLabel }}</b-button
          >
        </div>
        <div v-if="event.id">
          <b-button size="md" variant="danger" @click="stop()"
            >Detener</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import groupApi from "../api/groups";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      id: "",
      mensaje: "",
      title: "",
      type: "culto",
      event_group_id: "",
      groups: [],
      loading: false,
      linkError: "",
      survey_dispatch: "not_accepted",
    };
  },
  created() {
    this.getGroups();
    setTimeout(() => {
      if (this.event.id) {
        this.id = this.event.link;
        this.title = this.event.title;
        this.type = this.event.type;
        this.event_group_id = this.event.event_group_id;
      }
    }, 1000);
  },
  computed: {
    ...mapGetters({
      event: "events/getActiveEvent",
    }),
    submitLabel: function () {
      return this.event.id ? "Actualizar Evento" : "Iniciar Evento";
    },
  },
  methods: {
    submitEvent: function () {
      this.loading = true;
      if (this.event.id) {
        this.update();
      } else {
        this.start();
        this.$vToastify.success("", "Comenzó la transmisión!✅");
      }
    },
    getGroups: function () {
      groupApi.get().then((res) => {
        this.groups = res.data;
      });
    },
    start: function () {
      this.loading = true;
      var link = window.localStorage.site + "/api/culto";
      axios
        .post(link, {
          link: this.id,
          title: this.title,
          state: 1,
          type: this.type,
          event_group_id: this.event_group_id,
          user_id: this.$root.user.ID,
        })
        .then(() => {
          this.loading = false;
          this.$refs["transmision"].hide();
          this.$store.dispatch("events/getActiveEvent");
          console.debug("socket.emit: start event");
          this.$root.socket.emit("transmision", true);
        })
        .catch((error) => {
          console.log(error);
          this.mensaje = "Ha ocurrido un error, contacte a soporte";
        });
    },
    update: function () {
      this.loading = true;
      var link = window.localStorage.site + "/api/culto/" + this.event.id;
      axios
        .put(link, {
          link: this.id,
          title: this.title,
          type: this.type,
          event_group_id: this.event_group_id,
        })
        .then(() => {
          this.loading = false;
          this.$refs["transmision"].hide();
          this.$store.dispatch("events/getActiveEvent");
          console.debug("socket.emit: update event");
          this.$root.socket.emit("transmision", true);
        })
        .catch((error) => {
          console.log(error);
          this.mensaje = "Ha ocurrido un error, contacte a soporte";
        });
    },

    stop: function () {
      this.loading = true;
      var link = window.localStorage.site + "/api/culto/" + this.event.id;
      axios
        .put(link, {
          state: 0,
        })
        .then(() => {
          this.loading = false;
          this.mensaje = "";

          console.debug("socket.emit: stop event");
          this.$root.socket.emit("transmision", this.$root.cortina);
          this.$refs["transmision"].hide();
          this.id = "";
          this.title = "";
          this.type = "culto";
          if (this.survey_dispatch === "accepted") {
            this.$store.dispatch("survey/setShowSurveyEnablement", true);
          }
        })
        .catch((error) => {
          console.log(error);
          this.mensaje = "Ha ocurrido un error, contacte a soporte";
        });
    },
  },
};
</script>
